import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Popover, PopoverTrigger, PopoverContent, PopoverCloseButton } from '@entur/tooltip';
import { Heading4, Label } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';
import { Checkbox } from '@entur/form';
import { SecondaryButton, IconButton } from '@entur/button';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import { AdjustmentsIcon, CloseIcon } from '@entur/icons';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader"></PageHeader>
    <Playground __position={1} __code={'<Popover>\n  <PopoverTrigger>\n    <SecondaryButton>\n      <AdjustmentsIcon aria-hidden=\"true\" />\n      Filter\n    </SecondaryButton>\n  </PopoverTrigger>\n  <PopoverContent>\n    <div style={{ padding: \' 1rem\' }}>\n      <Heading4 margin=\"none\" style={{ display: \'inline-block\' }}>\n        Filter\n      </Heading4>\n      <PopoverCloseButton style={{ float: \'right\', display: \'inline-block\' }}>\n        <IconButton aria-label=\"Lukk popover\">\n          <CloseIcon aria-hidden=\"true\" />\n        </IconButton>\n      </PopoverCloseButton>\n      <GridContainer>\n        <GridItem small={3}>\n          <Label>Status</Label>\n        </GridItem>\n        <GridItem small={4}>\n          <Checkbox>Kladd</Checkbox>\n        </GridItem>\n        <GridItem small={5}>\n          <Checkbox>Publisert</Checkbox>\n        </GridItem>\n      </GridContainer>\n      <GridContainer style={{ marginBottom: \'1rem\' }}>\n        <GridItem small={3}>\n          <Label>Kategori</Label>\n        </GridItem>\n        <GridItem small={4}>\n          <Checkbox>App</Checkbox>\n        </GridItem>\n        <GridItem small={5}>\n          <Checkbox>Entur Sørvis</Checkbox>\n        </GridItem>\n        <GridItem small={3}></GridItem>\n        <GridItem small={4}>\n          <Checkbox>Web</Checkbox>\n        </GridItem>\n        <GridItem small={5}>\n          <Checkbox>Mobilterminal</Checkbox>\n        </GridItem>\n      </GridContainer>\n    </div>\n  </PopoverContent>\n</Popover>'} __scope={{
      props,
      DefaultLayout,
      Popover,
      PopoverTrigger,
      PopoverContent,
      PopoverCloseButton,
      Heading4,
      Label,
      GridContainer,
      GridItem,
      Checkbox,
      SecondaryButton,
      IconButton,
      DoDontGroup,
      DoDontCard,
      AdjustmentsIcon,
      CloseIcon,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Popover mdxType="Popover">
    <PopoverTrigger mdxType="PopoverTrigger">
      <SecondaryButton mdxType="SecondaryButton">
        <AdjustmentsIcon aria-hidden="true" mdxType="AdjustmentsIcon" />
        Filter
      </SecondaryButton>
    </PopoverTrigger>
    <PopoverContent mdxType="PopoverContent">
      <div style={{
            padding: ' 1rem'
          }}>
        <Heading4 margin="none" style={{
              display: 'inline-block'
            }} mdxType="Heading4">
          Filter
        </Heading4>
        <PopoverCloseButton style={{
              float: 'right',
              display: 'inline-block'
            }} mdxType="PopoverCloseButton">
          <IconButton aria-label="Lukk popover" mdxType="IconButton">
            <CloseIcon aria-hidden="true" mdxType="CloseIcon" />
          </IconButton>
        </PopoverCloseButton>
        <GridContainer mdxType="GridContainer">
          <GridItem small={3} mdxType="GridItem">
            <Label mdxType="Label">Status</Label>
          </GridItem>
          <GridItem small={4} mdxType="GridItem">
            <Checkbox mdxType="Checkbox">Kladd</Checkbox>
          </GridItem>
          <GridItem small={5} mdxType="GridItem">
            <Checkbox mdxType="Checkbox">Publisert</Checkbox>
          </GridItem>
        </GridContainer>
        <GridContainer style={{
              marginBottom: '1rem'
            }} mdxType="GridContainer">
          <GridItem small={3} mdxType="GridItem">
            <Label mdxType="Label">Kategori</Label>
          </GridItem>
          <GridItem small={4} mdxType="GridItem">
            <Checkbox mdxType="Checkbox">App</Checkbox>
          </GridItem>
          <GridItem small={5} mdxType="GridItem">
            <Checkbox mdxType="Checkbox">Entur Sørvis</Checkbox>
          </GridItem>
          <GridItem small={3} mdxType="GridItem"></GridItem>
          <GridItem small={4} mdxType="GridItem">
            <Checkbox mdxType="Checkbox">Web</Checkbox>
          </GridItem>
          <GridItem small={5} mdxType="GridItem">
            <Checkbox mdxType="Checkbox">Mobilterminal</Checkbox>
          </GridItem>
        </GridContainer>
      </div>
    </PopoverContent>
  </Popover>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "popover"
    }}>{`Popover`}</h3>
    <ImportStatement imports="Popover" mdxType="ImportStatement" />
    <Props of={Popover} mdxType="Props" />
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Popover er en ikke-modal dialog, men en flytende boks for å vise ekstra kontekstuell informasjon,
interaksjoner eller innhold på flere linjer, når brukerne hovrer eller fokuserer på et interaktivt element.
I motsetning til en vanlig Tooltip, kan en bruker samhandle med popover-inholdet, for eksempel med enkle handlinger som klikk på lenker og knapper.`}</p>
    <p>{`Popover kan brukes som en hjelpeguide når det er første gangen brukeren skal bruke en applikasjon
eller hvis man trenger å introdusere en ny funksjonalitet.
Popover kan også brukes til avanserte funksjoner som filterkontroll som kombinerer flere
formkontrolller som Radio, Checkbox, Datapicker og Dropdown.`}</p>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="Gjør" variant="success" textInBox mdxType="DoDontCard">
    <ul>
      <li>Bruk en handlingsknapp for å utløse en popover.</li>
      <li>Minst et interaktiv element skal være inne i en popover.</li>
      <li>
        Bruk hvis det er lite plass på siden, for mye informasjon til å ha det
        inline og hvis det skaper for mye rot å vise innholdet med en gang.
      </li>
      <li>
        Bruk en popover hvis du trenger at innholdet skal forbli åpent, uten å
        måtte hovre på elementet slik som Tooltip.
      </li>
    </ul>
  </DoDontCard>
  <DoDontCard title="Unngå" variant="warning" textInBox mdxType="DoDontCard">
    <ul>
      <li>
        Hvis det bare er en tekst som ikke er interaktiv, vurder å bruk en
        Tooltip istedet.
      </li>
      <li>
        Unngå å bruk en popover for skjemaer eller medieinnhold som video, bilde
        og grafikk. Vurder heller å bruke en Modal.
      </li>
      <li>
        Unngå å bruk popover når brukerne ikke trenger å se resten av innholdet
        på skjermen. Vurder heller en Modal.
      </li>
    </ul>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      